// Libraries
import React, {useEffect, useState} from 'react';
import {Link} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';
import {useTranslation} from 'react-i18next';
// Components
import Container from '@Components/Container';
import NavbarItem, {NavbarItemProps} from './NavbarItem';
// Hooks
import useWindowScroll from '@Hooks/useWindowScroll';
// Types
import {Tag} from '@Types/tags';
import classNames from 'classnames';

const Navbar = () => {
	const [isExpanded, setIsExpanded] = useState(false);
	const [isScrolled, setisScrolled] = useState(false);
	const scrollY = useWindowScroll();
	const {t, i18n} = useTranslation();

	const toggleExpandHandler = () => setIsExpanded(!isExpanded);

	const menuItems: NavbarItemProps[] = [
		{
			text: t('routes.allServices.websites.text'),
			link: t('routes.allServices.websites.link'),
			children: [
				{
					text: t('routes.allServices.landingPage.text'),
					link: t('routes.allServices.landingPage.link'),
				},
				{
					text: t('routes.allServices.multiPage.text'),
					link: t('routes.allServices.multiPage.link'),
				},
				{
					text: t('routes.allServices.onePage.text'),
					link: t('routes.allServices.onePage.link'),
				},
				{
					text: t('routes.allServices.creative.text'),
					link: t('routes.allServices.creative.link'),
				},
				{
					text: t('routes.allServices.headlessCms.text'),
					link: t('routes.allServices.headlessCms.link'),
				},
				{
					text: t('routes.allServices.seo.text'),
					link: t('routes.allServices.seo.link'),
				},
			],
			toggleExpandHandler,
		},
		{
			text: t('routes.allServices.apps.text'),
			link: t('routes.allServices.apps.link'),
			toggleExpandHandler,
		},
		{
			text: t('routes.portfolio.text'),
			link: t('routes.portfolio.link'),
			toggleExpandHandler,
		},
		{
			text: t('routes.blog.text'),
			link: t('routes.blog.link'),
			toggleExpandHandler,
		},
		{
			text: t('routes.contact.text'),
			link: t('routes.contact.link'),
			isHiglited: true,
			toggleExpandHandler,
		},
		{
			text: 'EN',
			link: '/en/',
			isCurrentLang: i18n.language === 'en',
			toggleExpandHandler,
		},
		{
			text: 'PL',
			link: '/',
			isCurrentLang: i18n.language === 'pl',
			toggleExpandHandler,
		},
	];

	useEffect(() => {
		setisScrolled(scrollY > 50);
	}, [scrollY]);

	return (
		<Container
			as={Tag.Nav}
			classes={classNames('navbar', {
				'navbar--expanded': isExpanded,
				'navbar--scrolled': isScrolled,
			})}>
			<Link
				to={t('routes.home.link')}
				className='navbar__logo'
				onClick={() => setIsExpanded(false)}>
				<StaticImage
					src='../../../assets/images/icon.png'
					height={36}
					alt='Innovate tech solutions'
					loading='lazy'
					placeholder='blurred'
					formats={['auto', 'webp']}
				/>
			</Link>
			<button
				onClick={toggleExpandHandler}
				className='navbar__button'
				aria-label='menu'>
				<span></span>
				<span></span>
			</button>
			<ol className='navbar__menu'>
				{menuItems.map((item) => (
					<NavbarItem key={item.text} {...item} />
				))}
			</ol>
		</Container>
	);
};

export default Navbar;
